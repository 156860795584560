import React from "react"
import { css } from "@emotion/react"

export default function Compliancy() {
  return (
      <div css={CompliancySectionStyles} style={{
        width: "100%",
        height: "100%"
      }}>

        <div id="guarantees-content-ctn" className="gutter">
          <h2>revelancy from compliancy</h2>
          <h5>We guarantee the validity of your answers</h5>
          <div id="guarantees-list-ctn">
            <div className="guarantee-ctn">
              <svg
                id="note-board-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 376 456"
              >
                <use href="/icons-sprite-map.svg#note-board-icon" />
              </svg>
              <div>
                <p>
                  Answers are useless unless extracted through a compliant
                  process. Our compliancy is your guarantee.
                </p>
                <p>
                  This is why we ensure <strong>100% norms compliancy</strong>
                </p>
                <ul>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>ICH - GxP</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>E9 statistical principles for clinical trials</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>
                        E3 sructure and content of clinIcal study reports
                      </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

const CompliancySectionStyles = css`


  #section-guarantees {
    margin-top: -0.049rem;
    margin-bottom: -0.4rem;
  }

  h2 {
    color: #080829;
    margin-top: 7rem;
  }

  h5 {
    color: #080829;
    margin-bottom: 9rem;
  }

  #guarantees-content-ctn {
    padding-bottom: 4rem;
  }

  #guarantees-list-ctn {
    max-width: 59.53rem;
    margin: auto;
  }

  #note-board-icon {
    width: 18.05rem;
    max-width: 100%;
  }

  .checkmark-icon {
    min-width: 0.96rem;
    max-height: 0.816rem;
    padding-right: 0.6rem;
  }

  .guarantee-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
  }

  .guarantee-ctn > div {
    max-width: 32rem;
    margin-left: 5rem;
  }

  .guarantee-ctn p {
    font-weight: 300;
    color: #1d2554;
  }

  .guarantee-ctn strong {
    font-weight: 500;
  }

  .guarantee-ctn ul {
    list-style-type: none;
    padding: 0;
    font-weight: 300;
    color: #1d2554;
  }

  .guarantee-ctn ul li {
    padding: 0.5rem;
    border-bottom: 0.144rem solid #c6c8d4;
    display: flex;
  }

  @media (max-width: 1023px) {
    #guarantees-content-ctn {
      padding-bottom: 2rem;
    }

    #section-guarantees h5 {
      margin-bottom: 4rem;
    }

    .guarantee-ctn {
      flex-direction: column;
      margin-bottom: 2rem;
    }

    .guarantee-ctn > div {
      margin: 0;
    }

    .guarantee-ctn p {
      text-align: center;
    }

    .guarantee-ctn p:last-of-type {
      margin-bottom: 2.5rem;
    }

    .guarantee-ctn > svg {
      margin-bottom: 1rem;
    }

    #note-board-icon {
      width: 14.445rem;
    }
  }

  @media (max-width: 479px) {
    #note-board-icon {
      width: 13rem;
    }
  }
`