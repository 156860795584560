import React from "react"
import "../assets/styles/global.css"

import { graphql, useStaticQuery } from "gatsby"
import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import HeaderServices from "../components/Header/HeaderServices/HeaderServices"
import DelimiterSVG, { SVGDelimiter } from "../components/Section/SVGDelimiter"
import StudySteps from "../components/Content/StudySteps/StudySteps"
import Compliancy from "../components/Content/Compliancy"
import Tools from "../components/Content/Tools"
import Testimonials from "../components/Content/Testimonials/Testimonials"
import ContactComponent from "../components/Content/Contact/ContactComponent"
import Footer from "../components/Bar/footer"
import { BIO_STATISTICS_TOOLTIPS } from "../const/MenuTooltips"
import { SEO_OPTIONS_BIOSTATISTICS } from "../const/SEOOptions"


const BiostatisticsPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {   
      headerImage: file(relativePath: { eq: "biostatistics.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }      
    }
  `)
  const fullpageOptions = {
    navigationTooltips: BIO_STATISTICS_TOOLTIPS
  }

  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_BIOSTATISTICS}
      background={"white"}
      location={location}
    >
      <Section isFirstSection>
        <HeaderServices
          h1={`<span>bio </span> <br /><span>statistics</span>`}
          p={`Answers from data.`}
          img={data.headerImage.childImageSharp.fluid}
          alt={`Biostatistics`}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#fff"}
        Background={`#141B48`}
      />

      <Section
        GradientBackground={`linear-gradient(180deg,rgba(20, 27, 72, 1) 0%,rgba(8, 8, 41, 1) 100%)`}
      >
        <StudySteps
          h2={"your study within 4 steps"}
          h5={`Adaptive methods, Precise Execution`}
          CTA={`Ask A Free Tailored Study Blueprint`}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#080829"}
        Background={`#fff`}
      />

      <Section>
        <Compliancy />
      </Section>

      <Section
      >
        <Tools />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={"#fff"}
        Background={`#010921`}
      />

      <Section
        Background={`#010921`}
      >
        <Testimonials />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={`#010921`}
        Background={`transparent`}
      />

      <ContactComponent
        DisplayContactForm={true}
      />
      <Footer location={location} />
    </FullPageLayout>
  )
}

export default BiostatisticsPage