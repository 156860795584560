import React from "react"
import { css } from "@emotion/react"

const Tools = () => {
  return (
    <div css={ToolsStyles}>
        <div id="tools-content-ctn" className="gutter">
          <h2>sharp tools, precise answers</h2>
          <div id="tool-list-ctn">
            <div className="tool-ctn">
              <img id="sas-logo" src="/sas-logo.png" alt="sas logo" />
              <h4>sas</h4>
            </div>
            <div className="tool-ctn">
              <img id="r-logo" src="/r-logo.png" alt="r logo" />
              <h4>r</h4>
            </div>
            <div className="tool-ctn">
              <img id="python-logo" src="/python-logo.png" alt="python logo" />
              <h4>python</h4>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Tools

const ToolsStyles = css`
  h2 {
    color: #080829;
    margin-bottom: 6.5rem;
  }

  #tools-content-ctn {
    padding-bottom: 8rem;
  }

  #tool-list-ctn {
    max-width: 60.96rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  #tool-list-ctn h4 {
    text-transform: uppercase;
    text-align: center;
  }

  .tool-ctn {
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }

  #sas-logo {
    width: 16.51rem;
    max-width: 100%;
  }

  #r-logo {
    width: 10.99rem;
    max-width: 100%;
  }

  #python-logo {
    width: 8.7854rem;
    max-width: 100%;
  }

  @media (max-width: 1023px) {
    #tools-content-ctn {
      padding-bottom: 4rem;
    }

    #tool-list-ctn {
      justify-content: center;
    }

    .tool-ctn {
      margin-bottom: 3rem;
    }
  }
`
